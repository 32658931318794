import { Routes, Route } from "react-router-dom";
import VLayout from "./components/visitor/VLayout";
// import ALayout from "./components/admin/ALayout";

// users
// import ALogin from "./components/admin/Login/ALogin";
// import ADashboard from "./components/admin/Dashboard/ADashboard"
// import PersistLogin from "./features/auth/PersistLogin"
// import RequireAuth from "./features/auth/RequireAuth"
// MODAL
import { Suspense, useEffect, useState} from "react";
import { ModalContainer } from "react-modal-global";
import Modal_1 from "./components/visitor/common/Modals/Modal_1";
// import Modal_2 from "./components/visitor/common/Modals/Modal_2";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// Details
import { ROLES } from "./config/roles";
// RTK Query
import { useGetDetailsMutation } from "./features/details/detailsApiSlice";
// IP
import { setAppVersion } from "./features/details/detailsSlice";
// Pages
import PageNotFound404 from './components/visitor/common/PageNotFound404'
import VMain from './components/visitor/pages/VMain/VMain'
// import VFaq from "./components/visitor/pages/VFaq/VFaq";
// import VAbout from './components/visitor/pages/VAbout/VAbout';
// import VBlog from './components/visitor/pages/VBlog/VBlog';

function App() {
    const dispatch = useDispatch();

    const [getDetails, {
        data: details,
        isLoading, 
        isSuccess, 
        isUninitialized,
        isError, 
        error
    }] = useGetDetailsMutation()
    
    const appVersion = useSelector(state => state.details.appVersion);
    
    useEffect( () => {  
        // Details
        getDetails()
        // force reload window
        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") {
              getDetails()
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [])

    useEffect(() => {
      if (isSuccess && details) {
          // details
          if(details.appVersion !== appVersion){
              dispatch(setAppVersion(details.appVersion))
              window.location.reload();
          }
          if(!showedOnce){
            setShowedOnce(true)
          }
      }
    }, [isSuccess, details])

    const [minimumLoadTimePassed, setMinimumLoadTimePassed] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setMinimumLoadTimePassed(true);
        }, 300); // Set minimum display time for preloader

        return () => clearTimeout(timer);
    }, []);

    const [showedOnce, setShowedOnce] = useState(false)
    if(isLoading || !minimumLoadTimePassed){
      if(!showedOnce || !minimumLoadTimePassed){
        // return <Preloader />
        return <span>loading</span>
      }
    }


    return (
      <>
            <Routes>
              <Route path="/" element={<VLayout />}>
                  <Route index element={<VMain />} />
                  <Route path='connect' element={<VMain />} />
                  <Route path='qr/:qrCode' element={<VMain />} />
                  {/* <Route path='blog' element={<VBlog />} /> */}
                  {/* <Route path='faq' element={<VFaq />} /> */}
                  <Route path="*" element={<PageNotFound404 />} />

                {/* admin */}
                {/* <Route path="admin"> */}
                    {/* DASHBOARD */}
                    {/* <Route index element={<ALogin />} /> */}
                    {/* Protected Routes */}
                    {/* <Route element={<PersistLogin ifUnauthorisedRedirectTo='/admin' />}>
                        <Route element={<ALayout />} >
                            <Route element={<RequireAuth allowedRoles={[ ROLES.Nurse, ROLES.Doctor, ROLES.Admin, ROLES.SuperAdmin, ROLES.Owner]} ifNotAllowedNavigateTo='/admin' />}>
                                <Route path="dashboard" element={<ADashboard/>} />
                              
                            </Route>
                        </Route>
                    </Route>


                </Route> */}
                
              </Route>
            </Routes>
        <Suspense>
            <ModalContainer controller={Modal_1} />
            {/* <ModalContainer controller={Modal_2} /> */}
        </Suspense>
      </>
    
  );
}

export default App;
